import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { Group } from "../helpers/objects";
import { AuthenticationService } from '../helpers/services';
import { MatButtonToggleChange, MatPaginator, MatSnackBar, MatTableDataSource } from "@angular/material";
import { FormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { Storage } from 'aws-amplify';


@Component({
  selector: 'app-personas',
  templateUrl: './personas.component.html',
  styleUrls: ['./personas.component.scss']
})
export class PersonasComponent implements OnInit, OnDestroy {
  toggle: boolean = true;
  dataSource: any;
  displayedColumns: string[] = ['select', 'options', 'checkedOut', 'name', 'type', 'currentLoc', 'dob', 'pob'];
  selection = new SelectionModel<Group>(true, []);
  personasWithPics = {};
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  PERSONA_API = 'chameleonDBAPI';
  public path = '/persona';
  public personaHeads: Group[] = [];
  public personaHeadsCheckedOut: Group[] = [];
  public personaHeadsCheckedIn: Group[] = [];
  public db: Group[] = [];
  public username = this.authenticationService.currentUserValue.username;
  public group = this.authenticationService.currentUserValue.groups;
  region: string;
  empty_personas: boolean = false;
  regionList = [ 'View All', 'Miami', 'NYC', 'Atlanta', 'Boston',
    'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'blackhorse' ];
  hhsList = [ 'View All', 'Miami', 'NYC', 'Atlanta', 'Boston',
    'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco' ];
  selectedVal: any;
  myControl = new FormControl();
  options: Group[] = [];
  find = false;
  groupCheckOut = [];
  groupCheck = false;
  allDataLoaded: boolean = false;
  public DB: Group[] = [];

  constructor(private router: Router,
              private amplifyService: AmplifyService,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              public snackBar: MatSnackBar) {
    this.region = localStorage.getItem('region');
    if (sessionStorage.getItem('gridView') != 'true') {
      this.toggle = false;
    }
  }

  ngOnInit() {
    if (this.group.includes("Regional-Managers")){
      this.getManagerData()
        .then(result => this.initProfilePics())
        .then(result => this.allDataLoaded = true);
    } else if (this.group.includes("Chameleons")){
      this.getData()
        .then(result => this.initProfilePics())
        .then(result => this.allDataLoaded = true);
    } else if (this.group.includes("Super-Admin")){
      this.getAdminData()
        .then(result => {
          this.dataSource.paginator = this.paginator;
          this.initProfilePics();
        }).then(result => {
          this.allDataLoaded = true;
        });
    } else if (this.group.includes("SeniorLeaderViewOnly")){
      this.getManagerData()
        .then(result => this.initProfilePics())
        .then(result => this.allDataLoaded = true);
    } else if (this.group.includes('JoeFieldSuper-Admin')){
      this.getAllPersonas()
        .then(result => this.initProfilePics())
        .then(result => {
          this.allDataLoaded = true;
        }).then(result => this.dataSource.paginator = this.paginator);
    }
  }

  ngOnDestroy() {
    sessionStorage.setItem('gridView', String(this.toggle));
  }

//filtering for the table search
  filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(val => this.filter(val))
    );

  //filter method for the table of personas
  filter(val: string): any[] {

    const filterValue = val.toLowerCase();
    this.options = this.personaHeads;
    this.onSearch(filterValue);
    return this.personaHeads.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  toggleView(change: MatButtonToggleChange){
    this.toggle = change.value;
  }

  //get size of personas
  getPersonaLength() {
    return this.personaHeads.length;
  }

  //applying filter to the personas and to the pages on the bottom
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //if all of the rows are selected for group in/checkout
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Group): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  private selectRow($event, dataSource) {
    // console.log($event.checked);
    if ($event.checked === true) {
      //console.log(dataSource.name);
      this.groupCheckOut.push(dataSource.name);
    }else{
      //console.log(dataSource.name);
      for(let n of this.groupCheckOut){
        if(dataSource.name === n){
          let i = this.groupCheckOut.indexOf(n);
          this.groupCheckOut.splice(i, 1);
        }
      }
    }

  }

//checking out selected methods
  checkThemAllOut() {
    //console.log(this.groupCheckOut);
    for(let ppl of this.personaHeads){
      for(let check of this.groupCheckOut){
        if(ppl['name'] == check){
          this.groupCheck = true;
          this.checkInOrOut(ppl['checkedOut'], check);
          //console.log(check);
        }
      }
    }
    this.groupCheckOut = [];
    this.selection.clear();
  }

  //getting data for users who are chameleons
  //these users are the bottom of the admin level and can only see users they create
  async getData(): Promise<Group[]> {
    let groups: Group[] = [];
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        for (let ppl of response) {
          if(ppl['user']=== this.username) {
            let group = new Group();
            group['name'] = ppl['name'];
            group['type'] = ppl['type'];
            group['currentLoc'] = ppl['currentLoc'];
            group['dob'] = ppl['dob'];
            group['pob'] = ppl['pob'];
            group['checkedOut'] = ppl['checkedOut'];
            group['gender'] = ppl['gender'];

            groups.push(group);
            this.personaHeads.push(group);
            
            if (ppl.accounts.length > 0) {
              group.accountCount = ppl.accounts.length;
            }

            if (group.checkedOut) {
              this.personaHeadsCheckedOut.push(group);
            } else {
              this.personaHeadsCheckedIn.push(group);
            }
          }
        }
      }
    }catch (error) {
      console.log("error");
    }
    if (this.personaHeads.length == 0)
    {
      this.empty_personas = true;
    }

    this.dataSource = new MatTableDataSource<Group>(this.personaHeads);
    return groups;
  }

  //this gets all the personas within a managers region
  //for exmaple if the manager is a manager of miami he can see all personas within the miami region
  async getManagerData(): Promise<Group[]> {
    let groups: Group[] = [];
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        for (let ppl of response) {
          if(ppl['regions'] === this.region) {
            let group = new Group();
            group['name'] = ppl['name'];
            group['type'] = ppl['type'];
            group['currentLoc'] = ppl['currentLoc'];
            group['dob'] = ppl['dob'];
            group['pob'] = ppl['pob'];
            group['checkedOut'] = ppl['checkedOut'];
            group['gender'] = ppl['gender'];

            groups.push(group);
            this.personaHeads.push(group);

            if (ppl.accounts.length > 0) {
              group.accountCount = ppl.accounts.length;
            }

            if (group.checkedOut) {
              this.personaHeadsCheckedOut.push(group);
            } else {
              this.personaHeadsCheckedIn.push(group);
            }
          }
        }
      }
    }catch (error) {
      console.log("error");
    }

    if (groups.length == 0)
    {
      this.empty_personas = true;
      console.log('emptyyyy' + this.empty_personas);
    }
    console.log(groups);

    this.dataSource = new MatTableDataSource<Group>(this.personaHeads);
    return groups;
  }


//this is the admin level
  //admins can see all personas created besides the ones that are in the blackhorse region
  async getAdminData(): Promise<Group[]> {
    let groups: Group[] = [];
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      console.log(response);
      if (response) {
        for (let ppl of response) {
          let group = new Group();
          group['name'] = ppl['name'];
          group['type'] = ppl['type'];
          group['currentLoc'] = ppl['currentLoc'];
          group['dob'] = ppl['dob'];
          group['pob'] = ppl['pob'];
          group['checkedOut'] = ppl['checkedOut'];
          group['gender'] = ppl['gender'];

          if (ppl.accounts.length > 0) {
            group.accountCount = ppl.accounts.length;
          }

          //groups.push(group);
          if (!(ppl['regions'] === 'blackhorse')){
            groups.push(group);
            this.personaHeads.push(group);
            if (group.checkedOut) {
              this.personaHeadsCheckedOut.push(group);
            } else {
              this.personaHeadsCheckedIn.push(group);
            }
          }
        }
      }
    }catch (error) {
      console.log("error");
    }
    this.dataSource = new MatTableDataSource<Group>(groups);
    return groups;
  }

  //this is the method that returns every single persona within the database
  async getAllPersonas() {
    this.personaHeads = [];
    let groups: Group[] = [];
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        for (let ppl of response) {
          let group = new Group();
          group['name'] = ppl['name'];
          group['type'] = ppl['type'];
          group['currentLoc'] = ppl['currentLoc'];
          group['dob'] = ppl['dob'];
          group['pob'] = ppl['pob'];
          group['checkedOut'] = ppl['checkedOut'];
          group['gender'] = ppl['gender'];

          groups.push(group);

          let repeat = false;
          for (let persona of this.personaHeads) {
            if (persona.name == group.name) {
              repeat = true;
            }
          }
          if (!repeat) {
            this.personaHeads.push(group);
          }

          if (ppl.accounts.length > 0) {
            group.accountCount = ppl.accounts.length;
          }

          if (group.checkedOut) {
            this.personaHeadsCheckedOut.push(group);
          } else {
            this.personaHeadsCheckedIn.push(group);
          }
        }
      }
    }catch (error) {
      console.log("error");
    }
    this.DB = groups;
    this.dataSource = new MatTableDataSource<Group>(groups);
  }

  //this sends the user to the page the creates a new persona
  onCreate() {
    this.router.navigate(['/register']);
  }

  //adds checkin checkout time to logs
  addCheckInOutLog(inputName: string, checkingOut: boolean, persona: []) {
    try {
      if (checkingOut) {
        persona['logs'].push({'user': inputName,
          'type': 'Checking Out', 'dateTime': this.getDate()});
      }
      else {
        persona['logs'].push({'user': inputName,
          'type': 'Checking In', 'dateTime': this.getDate()});
      }
    }
    catch (e) {
      console.log('asdfads fasd fas' + e);
    }

    return persona;
  }

  getDate() {
    let d = new Date();
    //Getting Date in consistent padding format
    let datestring = d.getFullYear() + "." +
      ("0" + (d.getMonth()+1)).slice(-2) + "." +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + ":" +
      ("0" + d.getMinutes()).slice(-2) + ":" +
      ("0" + d.getSeconds()).slice(-2);

    return datestring;
  }

//changes the personas check in/out status
  async checkInOrOut(stat, name): Promise<any> {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        for (let ppl of response) {
          if (ppl['name'] === name) {
            ppl['checkedOut'] = !stat;
            let newPersona = ppl;
            newPersona = this.addCheckInOutLog(this.username, ppl['checkedOut'],  ppl);

            const init = {
              body: newPersona
            };

            for(let persona of this.personaHeads){
              if(persona['name'] === name){
                persona['checkedOut'] = !persona['checkedOut'];
              }
            }

            this.amplifyService.api().post(this.PERSONA_API, '/persona', init).then(result => {
              if (this.groupCheck == false) {
                if (stat == false) {
                  alert("You have now checked out " + name.split('_')[1] + ", please remember to return it when you are done with the session.");
                } else {
                  let ischecked = confirm("You have now returned " + name.split('_')[1] + ", would you like to upload your session media, notes, and/or logs?");
                  if (ischecked == true) {
                    let log: any = 'logs';
                    this.router.navigate(['/personas/view', name], log);
                  }
                }
              }
            });
            this.dataSource = new MatTableDataSource<Group>(this.personaHeads);
          }
        }
      }
    }catch
      (error)
    {
      console.log("error" + error);
    }
    return;
  }

  //deletes the persona from the database
  public async deletePersona(name: string): Promise<boolean> {
    let response = false;
    let resp = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
    if (resp) {
      for (let ppl of resp) {
        if (ppl['name'] === name) {
          const init = {
            body: ppl
          };
          let isDelete = confirm("Are you sure you want to delete this persona? If so, hit OK");
          if(isDelete === true){
            let apiResp = await this.amplifyService.api().del(this.PERSONA_API, '/persona', init);
            if (apiResp) {
              if (apiResp['error']) {
                console.log('error)')
              } else {
                response = true;
                for(let persona of this.personaHeads){
                  if(persona['name'] === name){
                    const index: number = this.personaHeads.indexOf(persona);
                    if(index !== -1){
                      this.snackBar.open('Persona ' + name.split('_')[1] + ' has been deleted', 'Close', {duration: 5000});
                      this.personaHeads.splice(index, 1);
                      this.dataSource = new MatTableDataSource<Group>(this.personaHeads);
                    }
                    if (this.personaHeads.length == 0) {
                      this.empty_personas = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


    return response;
  }

  //this views the personas information
  onView(persona_name) {
    //console.log(this.router.navigate( ['/personas/view', persona_name]));
    this.router.navigate( ['/personas/view', persona_name]);
  }

  //sends the persona selected to the edit page
  onEdit(persona_name) {
    this.router.navigate(['/personas/edit', persona_name]);
  }

  //this allows the user to search through personas depending on the personas name, user, and regions.
  async onSearch(input: string){
    input = input.toUpperCase();
    this.personaHeads = [];
    let groups: Group[] = [];
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        for (let ppl of response) {
          if(ppl['user'].toUpperCase() == input
            || ppl['name'].toUpperCase().includes(input)
            || ppl['regions'].toUpperCase() == input
            || ppl['regions'].toUpperCase().includes(input)
            || ppl['user'].toUpperCase().includes(input)){
            let group = new Group();
            group['name'] = ppl['name'];
            group['type'] = ppl['type'];
            group['currentLoc'] = ppl['currentLoc'];
            group['dob'] = ppl['dob'];
            group['pob'] = ppl['pob'];
            group['checkedOut'] = ppl['checkedOut'];
            group['gender'] = ppl['gender'];

            if(this.group.includes('Super-Admin')){
              if(ppl['regions'] != 'blackhorse'){
                groups.push(group);
                this.find = true;
              }
            }else if(this.group.includes('JoeFieldSuper-Admin')) {
              if (group['name'] !== groups['name']) {
                groups.push(group);
                this.find = true;
              }
            }
          }
        }
      }
    }catch (error) {
      console.log("error" + error);
    }
    if(this.find === false){
      let rep = confirm("We are sorry, but no persona in the database contains that information. Try searching again.");
      if(rep === true){
        //this.getAdminData();
        this.router.navigate(['/personas']);}
      (<HTMLInputElement>document.getElementById('searching')).value = '';
    }
    this.personaHeads = groups;
    return;

    //return groups;
  }

  //this searches the personas depending on their region
  //if miami was chosen then all personas within that region will be displayed
  async onRegion(region: string) {
    let find = false;
    let dup = this.personaHeads;
    this.personaHeads = [];
    let groups: Group[] = [];
    if(region === 'View All'){
      this.personaHeads = this.DB;
      this.dataSource = new MatTableDataSource<Group>(this.personaHeads);
      return;
    }
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        console.log(response);
        for (let ppl of response) {
          if(ppl['regions'] == region) {
            console.log(ppl['regions']);
            console.log(ppl);
            let group = new Group();
            group['name'] = ppl['name'];
            group['type'] = ppl['type'];
            group['currentLoc'] = ppl['currentLoc'];
            group['dob'] = ppl['dob'];
            group['pob'] = ppl['pob'];
            group['checkedOut'] = ppl['checkedOut'];
            group['gender'] = ppl['gender'];

            groups.push(group);
            find = true;
            console.log(find);
            console.log(ppl);
          }
        }
      }
    }catch (error) {
      console.log("error");
    }
    console.log(find);
    if(find === false) {
      let rep = confirm("We are sorry, but there are no personas within this region. Please try another region.");
      if (rep === true) {
        this.personaHeads = this.DB;
        this.dataSource = new MatTableDataSource<Group>(this.personaHeads);
        return;
      }
    }
    this.personaHeads = groups;
    this.dataSource = new MatTableDataSource<Group>(groups);
    //(<HTMLInputElement>document.getElementById('rg')).value = '';
    console.log(groups);
    return groups;
  }

  /**
   * Calls to set the src of each profile photo for each persona
   * Stores in json object to be called in hmtl
   *
   */
  initProfilePics() {
    //for every persona of persona heads
    for (let persona of this.personaHeads) {
      let personaName = persona.name;
      let folderName = personaName.replace(/ /g, '');

      Storage.list(folderName)
        .then(result => {
          for (let entry of result) {
            if (String(entry.key).includes('profilepic.png')) {

              let fileName = folderName + '/profilepic.png';
              Storage.get(fileName)
                .then(result => {
                  let src = String(result);
                  this.personasWithPics[personaName] = src;
                });
            }
          }
        })
        .catch(err => console.log('error getting persona pics', err));
    }
  }

}




