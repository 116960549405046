import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticationService } from '../helpers/services';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { map, startWith } from 'rxjs/operators';
import { Storage } from 'aws-amplify';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

  //creating set up for accounts.
  data = {
    accounts: [
      {
        domain: "",
        username: "",
        password: ""
      }
    ]
  };

  personaInfoForm: FormGroup;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  //domains sorted alphabetically
  domains=['Gmail', 'Outlook', 'Facebook','LinkedIn', 'Twitter', 'Instagram',
    'Buffer', 'Yahoo', 'Pinterest', 'Wickr', 'Whatsapp', 'AOL', 'About.Me',
    'Canva', 'iCloud', 'Blur'].sort(function(a, b){
    if(a < b) { return -1; }
    if(a > b) { return 1; }
    return 0;
  });
  types = ['Digital', 'Physical'];
  genders = ['Female', 'Male'];
  PERSONA_API = 'chameleonDBAPI';
  regionList = ['Miami', 'NYC', 'Atlanta', 'Boston', 'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'blackhorse', 'Super-Admin'];
  username: string;
  group = this.authenticationService.currentUserValue.groups;
  region: string;
  selectedUser: string;
  hhsList = ['Miami', 'NYC', 'Atlanta', 'Boston', 'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'Super-Admin'];

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private amplifyService: AmplifyService,
              private authenticationService: AuthenticationService,
              public snackBar: MatSnackBar) {
    this.region = localStorage.getItem('region');
    this.username = localStorage.getItem('username');

    this.personaInfoForm = this.createFormGroup(formBuilder);
    this.selectedUser = this.personaInfoForm['user'];
    this.setAccounts();
  }

  //method creating a form for personas information
  createFormGroup(formBuilder: FormBuilder){   return formBuilder.group({
    name: [''],
    currentLoc: [''],
    mailingAdd: [''],
    pob: [''],
    type: [''],
    dob: [''],
    phone: [''],
    pin: [''],
    modelNum: [''],
    serialNum: [''],
    phoneNetwork: [''],
    imei: [''],
    gender: [''],
    hs: [''],
    college: [''],
    work: [''],
    hobbies: [''],
    user: [this.username],
    checkedOut: [false],
    regions: [this.region],
    //arrays, logs, and notes are all in form array - this affects how it gets changed, and viewed in other components
    accounts: this.formBuilder.array([
    ]),
    logs: this.formBuilder.array([
    ]),
    notes: this.formBuilder.array([]
    )
  })
  }

  ngOnInit() {
    //console.log(this.username);
    this.deleteAcct(0);
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.domains.filter(option=> option.toLowerCase().indexOf(filterValue) === 0);
  }

  uploadProfilePic(pic, personaName) {
    if (pic) {
      let name = this.username + '_' + personaName;
      if (personaName == '') {
        console.log('no valid name');
        return;
      }
      let fileName = name.replace(/ /g, '') + '/profilepic.png';
      Storage.put(fileName, pic.files[0]).catch(err => console.log(err));
    }
  }

  //this function is used through the entire create persona form. it is constantly saving
  onSubmit() {
    let result= this.personaInfoForm.value;
    let given_user = result['user'];
    //console.log(result);

    let input_name = result['name'];

    //a persona can have no name
    if (!input_name) {
      alert('A name is required');

      return;
    }

    //saving the accounts and it can not be empty
    let accounts = result['accounts'];
    //console.log(accounts);
    for(let i of accounts){
      if(i.domain === ''
      || i.username === ''
      || i.password === ''){
        alert('Account information must be changed.');
        return;
      }
    }

      if (!input_name.toString().includes('_')) {
        result['name'] = given_user + "_" + result['name'];
      }

    if (result['accounts'].length > 0 && result['accounts'][0] == {}) {
      console.log("shiftwed");
      result['accounts'].shift();
    }

    const path = '/persona';
    const init = {
      body: result
    };

    return this.amplifyService.api().post(this.PERSONA_API, path, init);
  }

  //this is the function that follows tbe button save And Exit.
  saveAndExit() {
    this.onSubmit().then(result => {
      if (result){
        this.router.navigate(['/personas']);
        this.snackBar.open('Persona has been saved', 'Close', {duration: 1000});
      }
    });
  }

  //this button does not save a persona but takes it out of this page and to all personas page
  exitPersona() {
    let isSaved = confirm("Hit OK if you saved before exiting the persona creation page.");
    if(isSaved === true)
      this.router.navigate(['/personas']);
  }

  //this creates new accounts and pushes this account to the control.
  addNewAccount() {
    let control = <FormArray>this.personaInfoForm.controls.accounts;
    control.push(
      this.formBuilder.group({
        domain:[''],
        username:[''],
        password:['']
      })
    )
  }

  //deleting an account for the array and formArray
  deleteAcct(index){
    let control = <FormArray> this.personaInfoForm.controls.accounts;
    control.removeAt(index);
  }

  //this sets the accounts up and stores what is inputted into each input
  setAccounts(){
    let control = <FormArray>this.personaInfoForm.controls.accounts;
    this.data.accounts.forEach(x => {
        control.push(this.formBuilder.group({
          domain: x.domain,
          username: x.username,
          password: x.password
        }))
    })
  }





}


