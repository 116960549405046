import { Component, OnInit, ViewChild } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticationService } from '../helpers/services';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { LogElement } from '../logs-upload/logs-upload.component';


@Component({
  selector: 'app-logs-display',
  templateUrl: './logs-display.component.html',
  styleUrls: ['./logs-display.component.scss']
})
export class LogsDisplayComponent implements OnInit {

  username: string;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  PERSONA_API = 'chameleonDBAPI';
  logs: LogElement[] = [];
  public group = this.authenticationService.currentUserValue.groups;
  dataSource: any;
  displayedColumns: string[] = ['uploader', 'persona', 'date', 'time', 'uploadType', 'media', 'comments'];

  constructor(private authenticationService: AuthenticationService,
              private amplifyService: AmplifyService) {
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');

    this.loadLogs().then(result =>
      this.dataSource.paginator = this.paginator
    );
  }

  parsePersonaLog(personaLogs, name) {
    if (!personaLogs) return;

    for (let entry of personaLogs) {
      this.logs.push(new LogElement(
        entry['user'] || 'user',
        name.split('_')[1],
        entry['dateTime'].split('_')[0],
        entry['dateTime'].split('_')[1],
        entry['type'] || 'Session',
        entry['media'],
        entry['text']
      ));
    }
  }

  /**
   * Loads logs and initializes them for use in the log table
   * Objects of the logs array are of LogElement type
   */
  async loadLogs() {
    this.logs = [];

    let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
    if (response) {
      for (let ppl of response) {
        //If the user is a chameleon only show their logs
        if (this.group.includes('Chameleons') && ppl['name'].split('_')[0] == this.username) {
          console.log('chameleon');
          this.parsePersonaLog(ppl.logs, ppl.name)
        }
        //If it is a regional manager then get all the ones from their region
        else if (this.group.includes('Regional-Managers') && this.group.includes(ppl['regions'])) {
          this.parsePersonaLog(ppl.logs, ppl.name)
        }
        //else then is admin show hhs
        else if (this.group.includes('Super-Admin') && !this.group.includes('blackhorse')) {
          this.parsePersonaLog(ppl.logs, ppl.name)
        }
        //else then is joe show all
        else if (this.group.includes('JoeFieldSuper-Admin')) {
          this.parsePersonaLog(ppl.logs, ppl.name)
        }
      }

      //Sorting entries by the date and time
      this.logs.sort((left, right): number => {
        if (left.date+left.time > right.date+right.time) {
          return -1;
        }
        if (left.date+left.time < right.date+right.time) {
          return 1;
        }
        return 0;
      });

    }

    this.dataSource = new MatTableDataSource<LogElement>(this.logs);
  }

}


