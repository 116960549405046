import { Component, OnInit } from '@angular/core';
import 'rxjs/Rx';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticationService } from '../helpers/services';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  PERSONA_API = 'chameleonDBAPI';
  path = '/resources';
  group = this.authenticationService.currentUserValue.groups;
  buildHTML: string;
  creationHTML: string;
  useHTML: string;
  recordingHTML: string;

  constructor(private amplifyService: AmplifyService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.initText();
  }

  async initText() {
    try {
      this.amplifyService.api().get(this.PERSONA_API, '/resources')
        .then(result => {
          for (let res of result) {
            if (res.name === 'build-editor')
              this.buildHTML = res.content;
            else if (res.name === 'creation-editor')
              this.creationHTML = res.content;
            else if (res.name === 'use-editor')
              this.useHTML = res.content;
            else if (res.name === 'recording-editor')
              this.recordingHTML = res.content;
          }
        })
        .catch(result => console.log(result));
    }
    catch (e) {
      console.log("error getting html file:", e);
    }

  }

}
