//this is the basic format for Personas information and everything that follows a persona.

export class PersonasInfoFULL {
  name: string;
  type: string;
  currentLoc: string;
  mailingAdd: string;
  dob: string;
  pob: string;
  phone: string;
  pin: string;
  modelNum: string;
  serialNum: string;
  phoneNetwork: string;
  imei: string;
  gen: string;
  hs: string;
  college: string;
  work: string;
  hobbies: string;
  accounts: [];
  checkedOut: boolean;
  regions: string;
  user: string;
  logs: [];
  notes: [];
}
