import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialsModule } from './material';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { QUILL_CONFIG, QuillConfigInterface, QuillModule } from 'ngx-quill-wrapper';
import { ChartsModule } from 'ng2-charts';
import { UsMapModule } from 'angular-us-map';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AddAlertDialog, AlertDialog, NavComponent } from './nav/nav.component';

import { PersonasComponent } from './personas/personas.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import 'hammerjs';
import { RegisterComponent } from './register/register.component';
import { 
  MatAutocompleteModule,
  MatDatepickerModule,
  MatInputModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSnackBarModule
 } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResourcesComponent } from './resources/resources.component';
import { PersonaPageComponent, AppPersonaPageDialog } from './persona-page/persona-page.component';
import { ViewEditPageComponent } from "./view-edit-page/view-edit-page.component";
import { ErrorInterceptor } from "./helpers/error-interceptor";
import { LogsUploadComponent } from './logs-upload/logs-upload.component';
import { ResourceEditingComponent } from './resource-editing/resource-editing.component';
import { NgCircleProgressModule } from "ng-circle-progress";
// Import angular-fusioncharts
import { FusionChartsModule } from "angular-fusioncharts";
// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionMaps from "fusioncharts/fusioncharts.maps";
import * as USA from "fusionmaps/maps/fusioncharts.usa";

import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import * as Florida from 'fusionmaps/maps/fusioncharts.florida';
import { MetricsComponent } from './metrics/metrics.component';
import { LogsDisplayComponent } from './logs-display/logs-display.component';

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(
  FusionCharts,
  Charts,
  FusionMaps,
  USA,
  Florida,
  FusionTheme
);

const DEFAULT_QUILL_CONFIG: QuillConfigInterface = {
  theme: 'snow',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      ['link', 'image'],
      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ]
  },
  placeholder: 'Empty canvas'
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PersonasComponent,
    RegisterComponent,
    NavComponent,
    ResourcesComponent,
    PersonaPageComponent,
    AppPersonaPageDialog,
    ViewEditPageComponent,
    LogsUploadComponent,
    ResourceEditingComponent,
    MetricsComponent,
    LogsDisplayComponent,
    AlertDialog,
    AddAlertDialog
  ],
  entryComponents: [AlertDialog, AddAlertDialog, AppPersonaPageDialog],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialsModule,
    FlexLayoutModule,
    MatSelectModule,
    MatDatepickerModule, MatInputModule, MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    AmplifyAngularModule,
    NgbModule,
    HttpClientModule,
    MatSnackBarModule,
    QuillModule,
    UsMapModule,
    ChartsModule,
    FusionChartsModule,
    MatButtonToggleModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300
    }), MatAutocompleteModule, MatProgressSpinnerModule
  ],
  providers: [ AmplifyService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: QUILL_CONFIG,
      useValue: DEFAULT_QUILL_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
