import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { PersonasComponent } from './personas/personas.component';
import { RegisterComponent } from './register/register.component';
import { ResourcesComponent } from './resources/resources.component';
import { ResourceEditingComponent } from './resource-editing/resource-editing.component';
import { MetricsComponent } from './metrics/metrics.component';

import { AuthGuard } from "./guards";
import { PersonaPageComponent } from './persona-page/persona-page.component';
import { ViewEditPageComponent } from "./view-edit-page/view-edit-page.component";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'resources', component: ResourcesComponent, canActivate: [AuthGuard]},
  { path: 'resources/edit', component: ResourceEditingComponent, canActivate: [AuthGuard]},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'personas', component: PersonasComponent, canActivate: [AuthGuard]},
  { path: 'personas/view/:persona_name', component: PersonaPageComponent, canActivate: [AuthGuard]},
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent},
  { path: 'personas/edit/:persona_name', component: ViewEditPageComponent, canActivate: [AuthGuard]},
  { path: 'metrics', component: MetricsComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
