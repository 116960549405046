import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticationService } from '../helpers/services';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Storage } from 'aws-amplify';


@Component({
  selector: 'app-view-edit-page',
  templateUrl: './view-edit-page.component.html',
  styleUrls: ['./view-edit-page.component.scss']
})

export class ViewEditPageComponent implements OnInit, OnDestroy {

  /*setting up data as the object array filled with objects called accounts.
  *each account has three attributes, domain, username, and password which ties to the personas i=online information
   */
  data = {
    accounts: [
      {
        domain: "",
        username: "",
        password: ""
      }
    ]
  };

  personaInfoForm: FormGroup;
  //domains sorted alphabetically
  domains=['Gmail', 'Outlook', 'Facebook','LinkedIn', 'Twitter', 'Instagram',
    'Buffer', 'Yahoo', 'Pinterest', 'Wickr', 'Whatsapp', 'AOL', 'About.Me',
    'Canva', 'iCloud', 'Blur'].sort(function(a, b){
    if(a < b) { return -1; }
    if(a > b) { return 1; }
    return 0;
  });
  types = ['Digital', 'Physical']; //a persona can be digital or physical
  genders = ['Female', 'Male']; //persona genders
  PERSONA_API = 'chameleonDBAPI'; //api that is being used in AWS
  public user = this.authenticationService.currentUserValue.username;
  public name: any;
  tempLogArr: any[] = [];
  tempNoteArr: any[]=[];
  regionList = ['Miami', 'NYC', 'Atlanta', 'Boston', 'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'blackhorse', 'Super-Admin'];
  region: string;
  group = this.authenticationService.currentUserValue.groups;
  hhsList=['Miami', 'NYC', 'Atlanta', 'Boston', 'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'Super-Admin'];
  sub: any;
  isSelected: boolean = false;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private amplifyService: AmplifyService,
              private authenticationService: AuthenticationService,
              public snackBar: MatSnackBar) {
    //console.log("createFormGroup");
    this.personaInfoForm = this.createFormGroup(formBuilder);
    this.setAccounts();
  }

  //method that creates the form group and its attributes that will contain the personas information
  createFormGroup(formBuilder: FormBuilder){   return formBuilder.group({
    name: [''],
    currentLoc: [''],
    mailingAdd: [''],
    pob: [''],
    type: [''],
    dob: [''],
    phone: [''],
    pin: [''],
    modelNum: [''],
    serialNum: [''],
    phoneNetwork: [''],
    imei: [''],
    gender: [''],
    hs: [''],
    college: [''],
    work: [''],
    hobbies: [''],
    user: [''],
    checkedOut: [''],
    regions: [''],
    logs: this.formBuilder.array([]),
    accounts: this.formBuilder.array([]),
    notes: this.formBuilder.array([])
  })
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.name = params['persona_name'];

      // In a real app: dispatch action to load the details here.
    });

    this.getPersonInfo();
    //console.log(this.accounts);

    this.deleteAcct(0);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  uploadProfilePic(pic, personaName) {
    if (pic) {
      let name = this.name;
      if (personaName == '') {
        console.log('no valid name');
        return;
      }
      let fileName = name.replace(/ /g, '') + '/profilepic.png';
      Storage.put(fileName, pic.files[0]).catch(err => console.log(err));
    }
  }

  //this method retrieves the personas information for editing.
  async getPersonInfo(): Promise<FormData> {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        for (let ppl of response) {
          if (ppl['name'] === this.name) {
            this.personaInfoForm.patchValue({
              name: ppl ['name'].split('_')[1] || ppl ['name'],
              type: ppl['type'],
              currentLoc: ppl['currentLoc'],
              mailingAdd: ppl['mailingAdd'],
              dob: ppl['dob'],
              pob: ppl['pob'],
              phone: ppl['phone'],
              pin: ppl['pin'],
              modelNum: ppl['modelNum'],
              serialNum: ppl[''],
              phoneNetwork: ppl['phoneNetwork'],
              imei: ppl['imei'],
              gender: ppl['gender'],
              hs: ppl['hs'],
              college: ppl['college'],
              work: ppl['work'],
              hobbies: ppl['hobbies'],
              regions: ppl['regions'],
              checkedOut: ppl['checkedOut'],
              user: ppl['user'],
            });
            //because of the form array, accounts must be stored to a local array
            for(let i of ppl["accounts"]){
              console.log(i);
              let control = <FormArray>this.personaInfoForm.controls.accounts;
              control.push(
                this.formBuilder.group({
                  domain:[i.domain],
                  username:[i.username],
                  password:[i.password]
                })
              )
            }
            //logs are not allowed to be edited
            for(let i of ppl['logs']){
              this.tempLogArr.push(i);
            }
            //notes can not be edited
            for(let i of ppl['notes']){
              this.tempNoteArr.push(i);
            }

            //console.log(this.personaInfoForm.value);
          }
        }
      }
    }catch (error) {
      console.log("error");
    }
    //console.log(this.personaInfoForm.value);
    return this.personaInfoForm.value;
  }

  //this method is used through out the html to constantly be storing information
  onSubmit() {
    let result= this.personaInfoForm.value;
    let input_name = result['name'];
    if (!input_name) {
      alert('A name is required'); //the name can not be empty.
      return;
    }
    if (!input_name.toString().includes('_')){
      result['name'] = result['user'] + "_" + result['name'];
    }
    let accounts = result['accounts'];
    //console.log(accounts);
    for(let i of accounts){
      //account information can not be blank
      if(i.domain === ''
        || i.username === ''
        || i.password === ''){
        alert('Account information must be changed.');
        return;
      }
    }

    //console.log(this.personaInfoForm['accounts']);
    const path = '/persona';
    const init = {
      body: result
    };
    //console.log("information of persona");
    //console.log(result);

    //pushing the new updated persona information
    return this.amplifyService.api().post(this.PERSONA_API, path, init);
  }

//this method allows the user to save and exit the page.
  saveAndExit() {
    //logs must be pushed back to the formbuilder
    for(let i of this.tempLogArr){
      this.logs.push(this.formBuilder.control(i));
    }
    //notes must be pushed back to the formbuilder
    for(let i of this.tempNoteArr){
      this.notes.push(this.formBuilder.control(i));
    }

    //when onSubmit occurs the user is taken back to the personas page.
    if (this.onSubmit()) {
      this.router.navigate(['/personas']);
      this.snackBar.open('Persona Saved', 'Close', {duration: 1000})
    }
  }

  //gets accounts from formArray
  get accounts(): FormArray {
    return this.personaInfoForm.get('accounts') as FormArray;
  }

  //gets logs from formArray
  get logs(): FormArray{
    return this.personaInfoForm.get('logs') as FormArray;
  }
  //gets notes from formArray
  get notes(): FormArray{
    return this.personaInfoForm.get('notes') as FormArray;
  }

  //this persona exits the page without updating the persona.
  //all changes made will not be saved
  exitPersona() {
    let isSaved = confirm("Hit OK if you saved before exiting the persona creation page.");
    if(isSaved === true)
      this.router.navigate(['/personas']);
  }

  //method to add a new account to the existing persona
  addNewAccount() {
    let control = <FormArray>this.personaInfoForm.controls.accounts;
    control.push(
      this.formBuilder.group({
        domain:[''],
        username:[''],
        password:['']
      })
    )
  }
  //method to delete any accounts in the existing persona
  deleteAcct(index){
    let control = <FormArray> this.personaInfoForm.controls.accounts;
    control.removeAt(index);
  }
  //this method sets the accounts in the data object array
  setAccounts(){
    let control = <FormArray>this.personaInfoForm.controls.accounts;
    this.data.accounts.forEach(x =>{
      control.push(this.formBuilder.group({
        domain: x.domain,
        username: x.username,
        password: x.password
      }))
    })
  }

  otherOption: Boolean = undefined;

  get(data) {
    this.isSelected = true;
    if (data.value == 'Other') {
      this.otherOption = true;
      console.log(data)
    }else {
      this.otherOption = false;
    }
  }

}

