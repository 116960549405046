import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from "../helpers/must-match";
import { AlertService, AuthenticationService } from '../helpers/services';
import { MatDialog } from '@angular/material/dialog';
import { AmplifyService } from "aws-amplify-angular";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  passwordForm: FormGroup;
  preForgotPassForm: FormGroup;
  forgotPasswordForm: FormGroup;
  submitted = false;
  returnUrl: string;
  currentForm: string = 'login';
  val = false;
  PERSONA_API = 'chameleonDBAPI';
  correct: boolean;
  username: string;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertService: AlertService,
              public dialog: MatDialog,
              private amplifyService: AmplifyService) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.passwordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$')]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
    this.preForgotPassForm = this.formBuilder.group({
      username:['', [Validators.required]],
      securityQuestion: ['', Validators.required]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get p() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      alert("You must enter a username and password");
      return;
    }

    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .then(data => {
          if (data['cognitoUser']['challengeName'] && data['cognitoUser']['challengeName'] === 'NEW_PASSWORD_REQUIRED') {
            console.log("Need to reset the password");
            this.currentForm = 'password';
            this.submitted = false;
          } else {
            //this.router.navigate([this.returnUrl]);
            this.setUserStorage();
            //prompting security question.
            this.promptQuestion();
            //console.log(this.correct);
          }
        },
        error => {
          alert("Something isn't right here, check your username and password");
        });
  }

  onPasswordSubmit() {
    this.submitted = true;

    if (this.passwordForm.invalid) {
      //alert("The password must contain at least 8 characters, a capital, and a number.");
      console.log("Username or password information is incorrect");

      return;
    }

    let newPassword = this.p.newPassword.value;
    this.authenticationService.setPassword(newPassword)
      .then(data => {
          this.addQuestion();
          alert("Password changed successfully.");
          //console.log("Password changed successfully");
          this.setUserStorage();
          this.currentForm = '';
          this.submitted = false;

          this.router.navigate(['/home']);
        },
        error => {
          alert("Error has occurred");
          //this.alertService.error(error['message']);
        });
  }

  async onForgotPasswordSubmit() {
    let alt = confirm('You have requested to reset your password. Please contact Joe for the verification code. You will need it going forward.');
    if (alt === true || alt === false ){
      //this.createPreForgotPassForm();
    }
    let username = prompt("What is your username");
    this.username = username;
    let ans = prompt("What is your father's middle name");

    try {
      console.log(username);
      console.log(ans);
      if(username === "" || ans === ""){
        alert('No username or security question was entered.');
        this.authenticationService.logout();
        return;
      }
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/loginQuestions');
      if (response) {
        for (let user of response) {
          if (user['userID'] === username) {
            if (user['answer'].toLowerCase() === ans.toLowerCase()) {
              //console.log("reached second if");
              this.correct = true;
              this.authenticationService.resetPassword(username)
                .then((data) => {
                  if (data) {
                    this.createForgotPasswordForm(username);
                    this.router.navigate(['/login']);
                  } else {
                    this.alertService.error("An error occurred. Please check the console for more details.");
                  }
                },
                error => {
                  this.alertService.error(error['message']);
                });
            } else {
              //output that the answer is false
              alert("Incorrect inputs.");
              this.authenticationService.logout();
              this.submitted = false;
              this.correct = false;
            }
          }
        }
      }
    } catch (error) {
      console.log("error" + error);
    }
  }

  //not used
  get forgot() {
    return this.forgotPasswordForm.controls;
  }

  get forgotNew() {
    return this.forgotPasswordForm.get('newPassword');
  }

  get forgotConf() {
    return this.forgotPasswordForm.get('confirmPassword');
  }


  createForgotPasswordForm(username: string) {
    this.forgotPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
      code:['']
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
    this.forgotPasswordForm.patchValue({
      user: username
    });
    this.currentForm = 'forgotPassword';
  }


  onForgotPasswordCodeSubmit() {
    let password = this.forgotPasswordForm.value.newPassword;
    let code = this.forgotPasswordForm.value.code;
    this.authenticationService.resetPasswordCodeSubmit(this.username,code,password)
      .then((data) => {
          if (data) {
            alert("You have successfully changed your password! Hopefully you will remember this one.");
            //this.alertService.success("Password changed successfully. Please log in.");
            this.authenticationService.logout();
            this.currentForm = 'login';
          } else {
            this.alertService.error("An error occurred. Please check the console for more details.");
          }
        },
        error => {
          this.alertService.error(error['message']);
        });
  }

  //'^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$')
  valthings(password: string) {
    let capital = /[A-Z]/g;
    let number = /[0-9]/g;
    let length = /(?=.{8,})/g;

    if (password.match(capital)) {
      //console.log('capital');
      if (password.match(number)) {
        //console.log('number');
        if (password.match(length)) {
          //console.log('length');
          this.val = true;
        }
      }
    }
    if (!password.match('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$')) {
      this.val = false;
    }
  }

  setUserStorage() {
    //Setting the username
    localStorage.setItem('username', this.authenticationService.currentUserValue.username);

    //Setting the users region
    let region: string;
    let group = this.authenticationService.currentUserValue.groups;
    if (group.includes("Miami")) {
      region = "Miami";
    } else if (group.includes("NYC")) {
      region = "NYC";
    } else if (group.includes("Atlanta")) {
      region = "Atlanta";
    } else if (group.includes("Boston")) {
      region = "Boston";
    } else if (group.includes("Chicago")) {
      region = "Chicago";
    } else if (group.includes("Dallas")) {
      region = "Dallas";
    } else if (group.includes("HQS")) {
      region = "HQS";
    } else if (group.includes("Kansas-City")) {
      region = "Kansas-City";
    } else if (group.includes("Los Angeles")) {
      region = "Los-Angeles";
    } else if (group.includes("Philadelphia")) {
      region = "Philadelphia";
    } else if (group.includes("San-Francisco")) {
      region = "San-Francisco";
    } else if (group.includes('blackhorse')) {
      region = "blackhorse";
    } else if (group.includes('Super-Admin') || group.includes('JoeFieldSuper-Admin')) {
      region = 'admin';
    }

    localStorage.setItem('region', region);
    sessionStorage.setItem('gridView', 'true');
  }

  async onSecurityQuestion(username: string, ans: string, count: number) {
    this.correct = false;
    let inDB = false;
    //response = api()
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/loginQuestions');
      //console.log(response);
      if (response) {
        for (let user of response) {
          if (user['userID'] === username) { //userID and username must match
            inDB = true;
            //console.log(user['userID']);
            if (user['answer'].toLowerCase() === ans.toLowerCase()) { //allows the user to input and it not be case sensitive
              this.correct = true;
              this.router.navigate(['/home']);
              //console.log(user['answer']);
            } else { //this occurs when the answer is false.
              //output that the answer is false
              alert("Incorrect answer.");
              this.authenticationService.logout();
              this.submitted = false;
              this.correct = false;
            }
          }
        }
      }
    } catch (error) {
      console.log("error");
    }

  }

  //connects to database and stores the answer the user has entered.
  async addSecurityQuestion(username: string, ans: string) {
    const path = '/loginQuestions';

    let result = {'userID': username, 'answer': ans};
    const init = {
      body: result
    };
    //console.log(init);
    alert('Your security question has been saved. You will need it EVERY time you log in.');
    this.router.navigate(['/home']);
    return this.amplifyService.api().post(this.PERSONA_API, path, init);
  }

  //this occurs when the user does not have a security question answer in the database.
  async addQuestion() {
    let sQ = prompt("Looks like you do not have a security question set up. Please enter your fathers MIDDLE name. This will be needed upon every login.");
    if (sQ === null) { //can not enter a null answer
    this.authenticationService.logout();
    return;
    }
    //goes to the Db and continues the method
    this.addSecurityQuestion(this.f.username.value, sQ);
  }

  //prompting question for user to answer
  //What is your fathers name
  //if the username is not in the Database then it exits and leaves the page on login
  async promptQuestion() {
    let inDB = false;
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/loginQuestions');
      //console.log(response);
      if (response) {
        for (let ppl of response) {
          if (ppl['userID'] === this.f.username.value) {
            inDB = true;
          }
        }
      } else {
        alert("there was a connection error");
        this.authenticationService.logout();
      }
    } catch (error) {
      console.log('error');
    }

    if (inDB === true) {
    let sQ = prompt("What is your fathers middle name");
    if(sQ === null) { //user can not enter a null
      this.authenticationService.logout();
      return;
    }
    //continuation of the methods
    this.onSecurityQuestion(this.f.username.value, sQ, 0);
    }else{
      //addQuestion sets the user up to create a security question
      this.addQuestion();
    }

    }
}
