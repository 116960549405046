import { Component, OnInit, } from '@angular/core';
import { AuthenticationService } from "../helpers/services";
import { AmplifyService } from "aws-amplify-angular";
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import * as jspdf from "jspdf";
import { animate, state, style, transition, trigger } from '@angular/animations';
import html2canvas from 'html2canvas';
import { LogElement } from '../logs-upload/logs-upload.component';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('0.5s 300ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class MetricsComponent implements OnInit {

  allDataLoaded: boolean = false;
  //Data Usage Constants
  userClass: USER_CLASS;
  PERSONA_API = 'chameleonDBAPI';

  //User info
  group: string;
  username: string;
  region: string = localStorage.getItem('region');
  //Statistic Data
  totalPersonaCount: number; //number of personas at current level
  physicalPersonaCount: number;
  digitalPersonaCount: number;
  totalPersonasCheckedOut: number;
  personaRegions: object;
  personaLogsUsed: {name: string, number: string}[];
  personaAccountsUsed;
  allPersonaLogs: LogElement[] = [];
  //Checking Chart Variables
  checkedOutChartLabels: Label[];
  checkedOutChartData: MultiDataSet;
  //Account Chart Variables
  accountsChartLabels: Label[];
  accountsChartData: MultiDataSet;
  accountsChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      fullWidth: false
    },
  };
  accountsChartColors = [
    {
      backgroundColor: [],
    }
  ];
  //Log Chart Variables
  logsChartLabels: Label[];
  logsChartData: ChartDataSets[];
  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        barThickness: 50,
        ticks: {
          min: 0
        }
      }],
      yAxes: [{
        ticks: {
          min: 0
        }
      }]
    }
  };
  //Map Data
  mapDataSource: Object;


  constructor(private authenticationService: AuthenticationService,
              private amplifyService: AmplifyService) {

    this.totalPersonaCount = 0; //number of personas at current level
    this.physicalPersonaCount = 0;
    this.digitalPersonaCount = 0;
    this.totalPersonasCheckedOut = 0;
    this.personaRegions = {
    'blackhorse': 0,
    'Atlanta': 0,
    'Boston': 0,
    'Chicago': 0,
    'Dallas': 0,
    'HQS': 0,
    'Kansas-City': 0,
    'Los-Angeles': 0,
    'Miami': 0,
    'NYC': 0,
    'Philadelphia': 0,
    'San-Francisco': 0
    };
    this.personaLogsUsed = [];
    this.personaAccountsUsed = [];

    this.checkedOutChartLabels = ['Physical Personas', 'Digital Personas'];
    this.checkedOutChartData = [[0, 0]];
    this.accountsChartLabels = [];
    this.accountsChartData = [[]];
    this.logsChartLabels = [];
    this.logsChartData = [{ data: [0, 0, 0], label: 'Most Used Personas' }];

    let groups = this.authenticationService.currentUserValue.groups;

    //Setting the user class which controls what personas get shown for the statistics
    if (groups.includes('Chameleons'))
      this.userClass = USER_CLASS.CHAMELEON;
    else if (groups.includes('Regional-Managers'))
      this.userClass = USER_CLASS.MANAGER;
    else if (groups.includes('Super-Admin'))
      this.userClass = USER_CLASS.ADMIN;
    else if (groups.includes('JoeFieldSuper-Admin'))
      this.userClass = USER_CLASS.JOE_ADMIN;

    this.initMetricData(this.userClass)
      .then( result => {
        //Initializing Physical/Digital Chart
        this.checkedOutChartData = [[this.physicalPersonaCount, this.digitalPersonaCount]];

        //Initializing accounts chart
        for (let key in this.personaAccountsUsed) {
          this.accountsChartLabels.push(key);
          this.accountsChartData[0].push(this.personaAccountsUsed[key]);
          this.accountsChartColors[0].backgroundColor.push(`rgba(${Math.floor(Math.random() * 256)},
          ${Math.floor(Math.random() * 256)},
          ${Math.floor(Math.random() * 256)},0.3)`);
        }

        //Sorting entries by the date and time
        this.allPersonaLogs.sort((left, right): number => {
          if (left.date+left.time > right.date+right.time) {
            return -1;
          }
          if (left.date+left.time < right.date+right.time) {
            return 1;
          }
          return 0;
        });

        //Initializing log chart
        let logData:{name: string, number: string}[] = this.findMostLoggedPersonas();
        this.logsChartLabels = [logData['first'].name.split('_')[1] || '',
          logData['second'].name.split('_')[1] || '', logData['third'].name.split('_')[1] || ''];
        this.logsChartData = [{ data: [Math.round(logData['first'].number), Math.round(logData['second'].number),
           Math.round(logData['third'].number)], label: 'Logs' }];

        this.exportMetricDataAsText();

        this.mapDataSource = {
          chart: {
            animation: "0",
            showbevel: "0",
            usehovercolor: "1",
            canvasbordercolor: "FFFFFF",
            bordercolor: "FFFFFF",
            caption: "National Persona Metrics",
            connectorcolor: "000000",
            fillalpha: "80",
            hovercolor: "CCCCCC",
            showborder: 0,
            theme: "fusion",
            showlegend: "0",
          },
          data: [
            { id: "CA", value: this.personaRegions['Los-Angeles'] },
            { id: "PA", value: this.personaRegions['Philadelphia'] },
            { id: "MA", value: this.personaRegions['Boston'] },
            { id: "IL", value: this.personaRegions['Chicago'] },
            { id: "TX", value: this.personaRegions['Dallas'] },
            { id: "VA", value: this.personaRegions['HQS'] },
            { id: "MO", value: this.personaRegions['Kansas-City'] },
            { id: "FL", value: this.personaRegions['Miami'] },
            { id: "NY", value: this.personaRegions['NYC'] },
            { id: "GA", value: this.personaRegions['Atlanta'] },
            { id: "CA", value: this.personaRegions['San-Francisco'] },
          ],
        };
      })
      .then(result =>  {
        this.allDataLoaded = true;

        document.getElementById('opacity-low').style.opacity = '1';
        console.log('alldatat loaded');
      })
      .catch(err => console.log('Could not init Dashboard'));
  }

  ngOnInit() {
  }

  /**
   * Initializes all basic metric data based on the user class
   * Different userClasses will get different scopes of data
   * user -> personal stats
   * manager -> region stats
   * admin -> national stats
   *
   * @param userClass
   */
  async initMetricData(userClass: USER_CLASS) {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');

      if (response) {
        if (userClass === USER_CLASS.ADMIN || userClass === USER_CLASS.JOE_ADMIN)
          this.getAdminData(response);
        else if (userClass === USER_CLASS.MANAGER)
          this.getManagerData(response);
        else if (userClass === USER_CLASS.CHAMELEON)
          this.getChameleonData(response);
      }
    }
    catch (error) {
      console.log("error");
    }
  }

  /**
   * Gets data allowed for admins: every region except blackhorse
   * Unless your are the joe admin level then you get everything
   *
   * @param data: all personas passed
   */
  private getAdminData(data) {
    for (let persona of data) {
      if (persona.regions != 'blackhorse' || this.userClass == USER_CLASS.JOE_ADMIN)
      {
        this.parsePersonaData(persona);
      }
      else {
        console.log('nope', persona.regions, this.userClass )
      }
    }
  }

  /**
   * Gets all the data allowed for managers: all personas from the manager of
   * that region.
   *
   * @param data: all the persona data
   */
  private getManagerData(data) {
    for (let persona of data) {
      if (persona.regions == localStorage.getItem('region')) {
        this.parsePersonaData(persona);
      }
    }
  }

  /**
   * Gets all the persona data allowed for chameleons: all personas made by
   * this user
   *
   * @param data: all the personas
   */
  private getChameleonData(data) {
    for (let persona of data) {
      if (persona.regions == localStorage.getItem('region') &&
            persona.name.split('_')[0] == localStorage.getItem('username')) {
        this.parsePersonaData(persona);
      }
    }
  }

  /**
   * Persona data that is used to parse a persona entry at any given level
   *
   * @param persona entry from data base
   */
  private parsePersonaData(persona) {
    this.totalPersonaCount++;

    //Recording all accounts so that it records their domain
    for (let acc of persona.accounts) {
      let accName = acc['domain'];
      //Ignoring null accounts
      if (accName == null) {continue;}
      if (this.personaAccountsUsed.hasOwnProperty(accName))
        this.personaAccountsUsed[accName]++;
      else
        this.personaAccountsUsed[accName] = 1;
    }

    //Add log to all persona logs
    for (let log of persona.logs) {
      this.allPersonaLogs.push(new LogElement(log.user, persona.name.split('_')[1], log.dateTime.split('_')[0],
        log.dateTime.split('_')[1], log.type, '',''));
    }
    this.personaLogsUsed.push({name : persona.name, number : persona.logs.length});

    //See if its digital or physical
    if (persona.type === 'Digital')
      this.digitalPersonaCount++;
    else if (persona.type === 'Physical')
      this.physicalPersonaCount++;

    //See if its checked out
    if (persona.checkedOut === true)
      this.totalPersonasCheckedOut++;

    //Adding all the regions
    if (this.personaRegions.hasOwnProperty(persona.regions))
      this.personaRegions[persona.regions]++;
    else
      this.personaRegions[persona.regions] = 1;

  }

  /**
   * Looping through all the personas that have been parsed for whatever level
   * and finding which are the top three personas that have been logged
   */
  private findMostLoggedPersonas() :  {name: string, number: string}[] {
    let topThree = [];
    topThree['first'] = {name: ' ', number: 0};
    topThree['second'] = {name: ' ', number: 0};
    topThree['third'] = {name: ' ', number: 0};

    for (let persona of this.personaLogsUsed) {
      let p = {name: persona.name, number: persona.number};

      if (p.number > topThree['first'].number) {
        let temp = {name: topThree['first'].name, number: topThree['first'].number};
        topThree['first'] = {name: p.name, number: p.number};
        p = temp;
      }
      if (p.number > topThree['second'].number) {
        let temp = {name: topThree['second'].name, number: topThree['second'].number};
        topThree['second'] = {name: p.name, number: p.number};
        p = temp;
      }
      if (p.number > topThree['third'].number) {
        let temp = {name: topThree['third'].name, number: topThree['third'].number};
        topThree['third'] = {name: p.name, number: p.number};
        p = temp;
      }
    }

    return topThree;
  }

  /**
   * Taking a screenshot of all the statistics on the page and saving it on
   * clients pc
   */
  public exportMetricDataAsPDF()
  {
    let data = document.getElementById('metrics-content');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      let imgWidth = 208;
      let imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      pdf.addImage(contentDataURL, 'PNG', 0,0, imgWidth, imgHeight);
      pdf.save(localStorage.getItem('username') + '_metrics.pdf'); // Generated PDF
    });
  }

  /**
   * Loads all logs shown in the logs section of the metrics and puts them into
   * a pdf and saves it to the clients pc.
   * All logs will be organized by date newest to oldest.
   */
  public exportAllLogs() {
    let strVer: string[] = [];
    for (let log of this.allPersonaLogs) {
      strVer.push(log.date + ', ' + log.time + ', ' + log.uploader + ', ' + log.persona + ', ' + log.type);
    }

    let pdf = new jspdf('p', 'pt', 'a4');
    let options = {
      pagesplit: true
    };

    //Formatting Page for all logs to print and extend pages
    let initialLine = 30;
    let count = initialLine;
    pdf.text('Date\t    Time\tUser\tPersona\tLog Type', 20, count, options);
    count += 20;
    for (let line of strVer) {
      pdf.text(line, 20, count, options);
      count += 20;

      if (count > 820) {
        pdf.addPage();
        count = initialLine;
        pdf.text('Date\t    Time\tUser\tPersona\tLog Type', 20, count, options);
        count += 20;
      }
    }

    pdf.save(localStorage.getItem('username') + '_metrics.pdf'); // Generated PDF
  }

  /**
   * All metrics exported as text
   *
   * Currently not inuse per Joe's request
   */
  public exportMetricDataAsText() {
    let obj = this.metricJSON();
    let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));

    let a = document.createElement('a');
    a.id = 'all-logs';
    a.href = 'data:' + data;
    a.download = localStorage.getItem('username') + '_metrics.json';

    let container = document.getElementById('export-text');
    container.appendChild(a);
  }

  public merticCLick(){
    document.getElementById('metrics-json').click();
  }

  /**
   * Exporting metrics as a json
   */
  private metricJSON() : object {
    let data: object = {};
    data['region'] = this.region;
    data['totalNumberOfPersonas'] = this.totalPersonaCount;
    data['numberOfPhysicalPersonas'] = this.physicalPersonaCount;
    data['numberOfDigitalPersonas'] = this.digitalPersonaCount;
    data['mostLogged'] = this.personaLogsUsed;
    data['accountsUsed'] = this.personaAccountsUsed;

    return data;
  }

}

//Setting the user class based on what work role they are
enum USER_CLASS {
  CHAMELEON,
  MANAGER,
  ADMIN,
  JOE_ADMIN
}

