import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';

import { Message, User } from '../objects';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private ADMINGROUP: string = 'ApplicationAdmin';
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public emailVer = 'julia.coleman@blackhorsesolutions.com';

  constructor(private amplifyService: AmplifyService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setCurrentUserValue(user: User) {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  async login(email: string, password: string): Promise<User> {
    var newUser = new User();
    var cognitoUser = await this.amplifyService.auth().signIn(email, password);
    if (cognitoUser) {
      newUser['cognitoUser'] = cognitoUser;
      newUser['username'] = cognitoUser.username;
      newUser['token'] = cognitoUser.userDataKey;
      if (!cognitoUser['challengeName']) {
        if (cognitoUser.signInUserSession.idToken.payload['cognito:groups']) {
          newUser['groups'] = cognitoUser.signInUserSession.idToken.payload['cognito:groups'];
          newUser['isAdmin'] = this.inAdminGroup(cognitoUser.signInUserSession.idToken.payload['cognito:groups']);  
        }
        var attributes = await this.amplifyService.auth().userAttributes(cognitoUser);
        for (let attribute of attributes) {
          let key = attribute.getName();
          let value = attribute.getValue();
          newUser[key] = value;
        }
      }

      sessionStorage.setItem('currentUser', JSON.stringify(newUser));
      this.currentUserSubject.next(newUser);
    }

    return newUser;
  }

  async update(user: User): Promise<Message> {
    var userAttributes: {[key: string]: any} = {};
    userAttributes['phone_number'] = user['phone_number'];
    userAttributes['given_name'] = user['given_name'];
    userAttributes['family_name'] = user['family_name'];
    try {
      let response = await this.amplifyService.auth().updateUserAttributes(user['cognitoUser'], userAttributes);

      if (response) {
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        let message = new Message();
        message['type'] = 'success';
        message ['text'] = 'Attributes updated successfully';
        return message;
      }
    } catch (error) {
      let message = new Message();
      message['type'] = 'error';
      message ['text'] = error['message'];
      return message;
  }

    return null;
  }

  async changePassword(currentPassword: string, newPassword: string): Promise<Message> {
    var currentUser = this.currentUserSubject.value.cognitoUser;
    try {
      var response = await this.amplifyService.auth().changePassword(currentUser, currentPassword, newPassword);
      if (response) {
        let message = new Message();
        message['type'] = 'success';
        message ['text'] = 'Password changed successfully';
        return message;
      }
    } catch (error) {
      let message = new Message();
      message['type'] = 'error';
      message ['text'] = error['message'];
      return message;
    }

    return null;
  }

  async setPassword(newPassword: string): Promise<User> {
    var newUser = new User();
    var currentUser = this.currentUserSubject.value.cognitoUser;
    console.log(this.currentUserSubject.value.cognitoUser);
    var cognitoUser = await this.amplifyService.auth().completeNewPassword(currentUser, newPassword, currentUser['challengeParam']['requiredAttributes']);
    console.log(cognitoUser);
    if (cognitoUser) {
      newUser['cognitoUser'] = cognitoUser;
      newUser['username'] = cognitoUser.username;
      newUser['token'] = cognitoUser.userDataKey;
      if (cognitoUser.signInUserSession.idToken.payload['cognito:groups']) {
        newUser['groups'] = cognitoUser.signInUserSession.idToken.payload['cognito:groups'];
        newUser['isAdmin'] = this.inAdminGroup(cognitoUser.signInUserSession.idToken.payload['cognito:groups']);  
      }
      var attributes = await this.amplifyService.auth().userAttributes(cognitoUser);
      console.log(attributes);
      for (let attribute of attributes) {
        let key = attribute.getName();
        let value = attribute.getValue();
        newUser[key] = value;
      }
    }

    sessionStorage.setItem('currentUser', JSON.stringify(newUser));
    this.currentUserSubject.next(newUser);

    return newUser;
  }

  //sending request for the user to reset their password.
  async resetPassword(username: string): Promise<boolean> {
    try {
      await this.amplifyService.auth().forgotPassword(username);
      return true;
    } catch (err) {
      console.log("An error occurred during password reset for " + username + ": ", err);
    }

    return false;
  }

  async resetPasswordCodeSubmit(username: string,  code: string, newPassword: string): Promise<boolean> {
    console.log(username);
    console.log(code);
    console.log(newPassword);
    try {
      await this.amplifyService.auth().forgotPasswordSubmit(username, code, newPassword);
      console.log('jere');
      return true;
    } catch (err) {
      alert("The code inputted is incorrect.");
      console.log("An error occurred during password submit for " + username + ": ", err);
    }
    return false;
  }


  async logout(): Promise<void> {
    var data = await this.amplifyService.auth().signOut();
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

    return null;
  }

  inAdminGroup(groups: string[]): boolean {

    let isAdmin: boolean = false;
    for (let element of groups) {
      if (element === this.ADMINGROUP) {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
  }
}
